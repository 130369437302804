<template>
  <page-container title="角色管理" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="角色名称" prop="rolename">
              <a-input v-model.trim="queryParams.rolename" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="角色类型" prop="roletype">
              <a-select v-model="queryParams.roletype">
                <a-select-option key="empty" value="">请选择</a-select-option>
                <a-select-option v-for="(role, index) in createUserTypes" :key="index" :value="role.dickey">{{role.dicvalue}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <!-- <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button> -->
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">角色列表</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="roleid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="roletype" slot-scope="text" class="status-view">
            <span class="text">{{userTypesMap[''+text]||''}}</span>
          </span>
          <span slot="operation" slot-scope="value, record">
            <!-- <a-button type="link" size="small" @click="showModal('detail', value, record, index)">详情</a-button>
            <a-button type="link" size="small" @click="showModal('edit', value, record, index)">修改</a-button>
            <a-button type="link" size="small" @click="deleteConfirm(value, record, index)">删除</a-button>
            <a-button type="link" size="small" @click="authMenuModal(value, record, index)">菜单授权</a-button> -->
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.roleid">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.roleid">修改</a-menu-item>
                <a-menu-item :key="'menu-'+record.roleid">菜单授权</a-menu-item>
                <a-menu-item :key="'delete-'+record.roleid">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal v-model="modalVisible" :title="modalTitle" :bodyStyle="{maxHeight: '400px', overflow: 'auto'}">
      <template slot="footer">
        <a-button v-if="modalType=='add'||modalType=='edit'" @click="modalCancel">取消</a-button>
        <a-button type="primary" @click="modalConfirm">确认</a-button>
      </template>
      <a-form-model ref="modalForm" :model="modalForm" :rules="modalRules" :label-col="{span:6}" :wrapper-col="{span:14}">
        <a-form-model-item label="角色名称" prop="rolename">
          <a-input v-model.trim="modalForm.rolename" />
        </a-form-model-item>
        <a-form-model-item label="角色类型" prop="roletype">
          <a-select v-model="modalForm.roletype">
            <a-select-option v-for="(role, index) in createUserTypes" :key="index" :value="role.dickey">{{role.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="描述" prop="roledesc">
          <a-input v-model.trim="modalForm.roledesc" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal v-model="menuModal" title="菜单授权" :bodyStyle="{position: 'relative', height: '400px', overflow: 'hidden'}">
      <template slot="footer">
        <a-button @click="menuModal=false">取消</a-button>
        <a-button type="primary" @click="setMenus">确认</a-button>
      </template>
      <div class="menu-modal-container">
        <div class="box-container" style="width: calc(50% - 10px); padding: 0; border-right: solid 1px #e5e5e5;">
          <div class="box-container-inner" style="padding: 0;">
            <h3>主菜单</h3>
            <a-tree
              checkable
              checkStrictly
              v-model="mainCheckedKeys"
              :default-expand-all="false"
              :replace-fields="{children:'children', title:'menuname', key:'menuid'}"
              :tree-data="mainMenuList"
              @select="mainMenuSelect"
              @check="mainMenuCheck"
            ></a-tree>
          </div>
        </div>
      </div>
      <div class="box-container" style="position: absolute; top: 24px; right: 24px; left: 50%; padding: 0;">
        <div class="box-container-inner" style="padding: 0 0 0 15px;">
          <h3>操作菜单</h3>
          <a-checkbox-group v-model="operationCheckedKeys" :options="operationMenus" />
        </div>
      </div>
    </a-modal>
  </page-container>
</template>
<script>
import { getRoleListByCondition, addRole, editRole, deleteRole, getMenuListByRoleid, authMenu, getDictByDicType } from 'A/xtpz.js'
import { getItemFromArrayByKey, filterTreeByKey } from 'U'
import store from '@/store'
import { mapState } from 'vuex'
export default {
  name: 'roleManagement',
  data() {
    return {
      userTypes: [],
      breadcrumb: [
        {
          name: '系统管理',
          path: ''
        },
        {
          name: '角色管理',
          path: ''
        },
      ],
      queryParams: {
        rolename: '',
        roletype: '',
      },
      tableColumns: [
        {
          title: '角色名称',
          dataIndex: 'rolename',
          key: 'rolename'
        },
        {
          title: '角色类型',
          dataIndex: 'roletype',
          key: 'roletype',
          sorter: function(a,b) {
            return a.roletype - b.roletype;
          },
          scopedSlots: { customRender: 'roletype' }
        },
        {
          title: '描述',
          dataIndex: 'roledesc',
          key: 'roledesc'
        },
        {
          title: '操作',
          key: 'operation',
          width: 80,
          align: 'center',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 10,
      },
      modalVisible: false,
      modalType: '',
      modalForm: {
        roleid: '',
        rolename: '',
        roletype: '',
        roledesc: '',
      },
      modalRules: {
        rolename: [{required: true, message: '请输入角色名称'}],
        roletype: [{required: true, message: '请选择角色类型'}],
      },
      selectedRole: null,
      menuModal: false,
      checkedKeys: [],
      mainCheckedKeys: {
        checked: [],
        halfChecked: [],
      },
      mainMenu: null,
      operationMenus: [],
      operationCheckedKeys: [],
      operationCheckedMap: {}
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      menuList: state => state.menuList
    }),
    modalTitle() {
      let title = '';
      if(this.modalType == 'add') {
        title = '新增';
      }else if(this.modalType == 'detail') {
        title = '详情';
      }else if(this.modalType == 'edit') {
        title = '修改';
      }else {
        title = '';
      }
      return title;
    },
    mainMenuList() {
      return filterTreeByKey(JSON.parse(JSON.stringify(this.menuList)), 'menutype', 1);
    },
    createUserTypes() {
      if(this.userInfo.usertype == '0') {
        if(this.userInfo.useraccount == 'admin') {
          return this.userTypes;
        }else {
          return this.userTypes.filter(item => item.dickey != '0');
        }
      }else {
        return this.userTypes.filter(item => item.dickey == this.userInfo.usertype);
      }
    },
    userTypesMap() {
      let result = {};
      this.userTypes.forEach(item => {
        result[item.dickey] = item.dicvalue
      })
      return result;
    },
  },
  watch: {
    modalVisible(val) {
      if(!val) {
        this.resetModal();
      }
    },
    menuModal(val) {
      if(!val) {
        this.checked
      }
    },
    operationCheckedKeys(val) {
      if(this.mainMenu) {
        this.operationCheckedMap[this.mainMenu.menuid] = val;
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    if(!toTab) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getUserTypes();
      this.getTableData();
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
    },
    getUserTypes() {
      let params = {
        dictype: '11'
      };
      getDictByDicType(params).then(res => {
        if(res && res.returncode == '0') {
          // if(this.userInfo.usertype == '0') {
          //   if(this.userInfo.useraccount == 'admin') {
              this.userTypes = res.item;
          //   }else {
          //     this.userTypes = res.item.filter(item => item.dickey != '0');
          //   }
          // }else {
          //   this.userTypes = res.item.filter(item => item.dickey == this.userInfo.usertype);
          // }
        }
      })
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      };
      getRoleListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    pageChange(page, size) {
      this.getTableData();
    },
    onShowSizeChange(page, size) {
      this.pagination.pageSize = size;
      this.getTableData(true);
    },
    showModal(type, value, record) {
      this.modalType = type;
      this.modalVisible = true;
      this.$nextTick(() => {
        if(type == 'add') {
          this.modalForm.roleid = '';
          this.modalForm.rolename = '';
          this.modalForm.roletype = '';
          this.modalForm.roledesc = '';
        }else {
          // TODO: 查询回显
          this.modalForm.roleid = record.roleid;
          this.modalForm.rolename = record.rolename;
          this.modalForm.roletype = ''+record.roletype;
          this.modalForm.roledesc = record.roledesc;
        }
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.roleid);
      }).catch(()=>{
      });
    },
    delete(roleid) {
      this.showLoading();
      if(roleid) {
        let params = {
          roleid
        };
        deleteRole(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    modalConfirm() {
      if(this.modalType == 'add' || this.modalType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if(valid) {
            this.addOrEdit();
          }else {
            return false;
          }
        })
      }else {
        this.modalVisible = false;
      }
    },
    addOrEdit() {
      this.showLoading();
      let params = {
        roleid: this.modalForm.roleid,
        roletype: this.modalForm.roletype,
        rolename: this.modalForm.rolename,
        roledesc: this.modalForm.roledesc,
      };
      if(this.modalType == 'add') {
        addRole(params).then(this.addOrEditResult);
      }else if(this.modalType == 'edit') {
        editRole(params).then(this.addOrEditResult)
      }
    },
    addOrEditResult(res) {
      this.hideLoading();
      if(res && res.returncode == '0') {
        this.$message.success('操作成功');
        this.modalVisible = false;
        this.getTableData();
      }else {
        this.$message.error(res.errormsg||'操作失败');
      }
    },
    modalCancel() {
      this.modalVisible = false;
    },
    resetModal() {
      this.$refs.modalForm.resetFields();
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let roleid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'roleid', roleid);
      if(type == 'menu') {
        this.authMenuModal('', record)
      }else if(type == 'detail') {
        this.showModal('detail', roleid, record);
      }else if(type == 'edit') {
        this.showModal('edit', roleid, record);
      }else if(type == 'delete') {
        this.deleteConfirm(roleid, record);
      }
    },
    authMenuModal(value, record, index) {
      this.mainCheckedKeys.checked = [];
      this.selectedRole = record;
      this.showLoading();
      let params = {
        roleid: record.roleid
      }
      getMenuListByRoleid(params).then(res => {
        if(res && res.returncode == '0') {
          this.checkedKeys = res.item;
          res.item.filter(item => item.menutype==1).map(item => item.menuid).forEach(item => {
            if(this.mainCheckedKeys.checked.indexOf(item) < 0) {
              this.mainCheckedKeys.checked.push(item);
            }
          })
          this.initOpeartionCheckedMap();
        }
        this.hideLoading();
      })
      this.menuModal = true;
    },
    initOpeartionCheckedMap() {
      let operationMenus = this.checkedKeys.filter(item => item.menutype == 2);
      operationMenus.forEach(item => {
        if(!this.operationCheckedMap[item.parentmenuid]) {
          this.operationCheckedMap[item.parentmenuid] = [];
        }
        if(this.operationCheckedMap[item.parentmenuid].indexOf(item.menuid) < 0) {
          this.operationCheckedMap[item.parentmenuid].push(item.menuid);
        }
      })
    },
    mainMenuSelect(selectedKeys, e) {
      if(e.selected) {
        // 选中
        let checkedMenuid = selectedKeys[selectedKeys.length-1];
        this.getOperationMenus(checkedMenuid);
      }else {
        // 取消选中
        this.getOperationMenus();
      }
    },
    mainMenuCheck(checkedKeys, e) {
      // checkbox选中
      if(e.checked) {
        // 选中
        let checkedMenuid = e.node.dataRef.menuid;
        this.selectAllCascaderMenus(checkedMenuid, true);
      }else {
        // 取消选中
        let checkedMenuid = e.node.dataRef.menuid;
        this.selectAllCascaderMenus(checkedMenuid);
      }
    },
    getOperationMenus(checkedMenuid) {
      if(checkedMenuid || checkedMenuid===0) {
        this.mainMenu = getItemFromArrayByKey(this.menuList, 'menuid', checkedMenuid);
        if(this.mainMenu.children && this.mainMenu.children.length) {
          this.operationMenus = this.mainMenu.children.filter(item => item.menutype==2).map(item => {
            return {
              value: item.menuid,
              label: item.menuname
            }
          })
          if(this.operationMenus.length) {
            if(this.operationCheckedMap[this.mainMenu.menuid]) {
              this.operationCheckedKeys = [...this.operationCheckedMap[this.mainMenu.menuid]];
            }else {
              this.operationCheckedKeys = [];
            }
          }else {
            this.operationCheckedKeys = [];
          }
        }else {
          this.operationMenus = [];
          this.operationCheckedKeys = [];
        }
      }else {
        this.mainMenu = null;
        this.operationMenus = [];
        this.operationCheckedKeys = [];
      }
    },
    selectAllCascaderMenus(checkedMenuid, checked) {
      // 查询所有相关菜单(1.所有父级菜单 2.所有子集菜单 3.所有操作菜单)
      if(checked) {
        // 所有父菜单跟随选中
        let allParents = this.getAllParentMenus(this.menuList, checkedMenuid, []);
        allParents.forEach(item => {
          if(item.menutype == 1 && this.mainCheckedKeys.checked.indexOf(item.menuid) < 0) {
            this.mainCheckedKeys.checked.push(item.menuid);
          }
        })
      }else {
        let idIndex = this.mainCheckedKeys.checked.indexOf(checkedMenuid)
        if(idIndex > -1) {
          this.mainCheckedKeys.checked.splice(idIndex, 1);
        }
      }
      // 所有子菜单跟随选中
      let checkedMenu = getItemFromArrayByKey(this.menuList, 'menuid', checkedMenuid);
      if(checkedMenu.children && checkedMenu.children.length) {
        let allChildMenus = this.getAllChildMenus(checkedMenu.children, []);
        if(checked) {
          allChildMenus.forEach(item => {
            if(item.menutype == 1 && this.mainCheckedKeys.checked.indexOf(item.menuid) < 0) {
              this.mainCheckedKeys.checked.push(item.menuid);
            }else if(item.menutype == 2){
              // if(!this.operationCheckedMap[item.parentmenuid]) {
              //   this.operationCheckedMap[item.parentmenuid] = [];
              // }
              // if(this.operationCheckedMap[item.parentmenuid].indexOf(item.menuid) < 0) {
              //   this.operationCheckedMap[item.parentmenuid].push(item.menuid);
              // }
              // if(this.mainMenu && this.mainMenu.menuid == item.parentmenuid) {
              //   // 当前选中菜单的操作菜单列表
              //   if(this.operationCheckedKeys.indexOf(item.menuid) < 0) {
              //     this.operationCheckedKeys.push(item.menuid);
              //   }
              // }
            }
          })
        }else {
          allChildMenus.forEach(item => {
            if(item.menutype == 1) {
              let idIndex = this.mainCheckedKeys.checked.indexOf(item.menuid)
              if(idIndex > -1) {
                this.mainCheckedKeys.checked.splice(idIndex, 1);
              }
            }else if(item.menutype == 2){
              if(this.operationCheckedMap[item.parentmenuid] && this.operationCheckedMap[item.parentmenuid].length) {
                let idIndex = this.operationCheckedMap[item.parentmenuid].indexOf(item.menuid);
                if(idIndex > -1) {
                  this.operationCheckedMap[item.parentmenuid].splice(idIndex, 1);
                }
              }
              if(this.mainMenu && this.mainMenu.menuid == item.parentmenuid) {
                // 当前选中菜单的操作菜单列表
                let opIndex = this.operationCheckedKeys.indexOf(item.menuid);
                if(opIndex > -1) {
                  this.operationCheckedKeys.splice(opIndex, 1);
                }
              }
            }
          })
        }
      }
    },
    getAllParentMenus(arr, checkedMenuid, result) {
      // mainCheckedKeys
      let newRes = [...result];
      for(let i=0; i<arr.length; i++) {
        let menu = arr[i];
        newRes.push(menu);
        if(menu.menuid == checkedMenuid) {
          break;
        }else if(menu.children) {
          let childArr = this.getAllParentMenus(menu.children, checkedMenuid, newRes)
          if(childArr.length > newRes.length) {
            newRes = childArr;
            break;
          }else {
            newRes = [...result];
          }
        }else {
          newRes = [...result];
        }
      }
      return newRes;
    },
    getAllChildMenus(arr, result) {
      arr.forEach(item => {
        result.push(item);
        if(item.children) {
          this.getAllChildMenus(item.children, result);
        }
      })
      return result;
    },
    setMenus() {
      let checkedKeys = [...this.mainCheckedKeys.checked];
      this.mainCheckedKeys.checked.forEach(item => {
        if(this.operationCheckedMap[item] && this.operationCheckedMap[item].length) {
          checkedKeys = [...checkedKeys, ...this.operationCheckedMap[item]];
        }
      })
      let params = {
        roleid: this.selectedRole.roleid,
        menuids: checkedKeys.join('|')
      }
      authMenu(params).then(res => {
        if(res && res.returncode == '0') {
          this.$message.success('操作成功');
          this.menuModal = false;
        }else {
          this.$message.error(res.errormsg||'操作失败');
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.menu-modal-container {
  // display: flex;
  // position: relative;
  height: 100%;
  overflow-y: auto;
  margin-right: -24px;
}
</style>